import React, { useEffect, useState } from "react";
import { HelpOutline, Language, Search } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputBase,
  Link,
  Toolbar,
  Typography,
} from "@mui/material";
import LanguageRender from "../renders/languageRender";
import { fetchAPI } from "../../api";
import { useDebounce } from "use-debounce";
import { apm } from "../../../src/index";

export default function DefaultPageHeader({
  headerDatas,
  changeLanguage,
  language,
}) {
  const [anchorLang, setAnchorLang] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [noItemsFound, setNoItemsFound] = useState(false);
  const [debounce] = useDebounce(searchInput, 200);
  const isLanguageOpen = Boolean(anchorLang);

  const handleHelp = () => {
    window.location.href =
      "https://inovitas.atlassian.net/servicedesk/customer/portals";
  };

  const handleLanguageMenuOpen = (event) => {
    setAnchorLang(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setAnchorLang(null);
  };

  useEffect(() => {
    // Start a transaction for the search
    const searchTransaction = apm.startTransaction("Search", "search");

    // Add user-defined labels
    searchTransaction.addLabels({
      keyword: debounce,
    });

    if (debounce !== "") {
      fetchAPI("items/pages", {
        filter: JSON.stringify({
          $or: [
            {
              title: {
                $regex: debounce,
                $options: "i",
              },
            },
            {
              description: {
                $regex: debounce,
                $options: "i",
              },
            },
          ],
        }),
        locale: language === null ? "" : language,
        populate: 1,
      })
        .get()
        .then((response) => {
          setFilteredList(response.data);
          if (response.data.length === 0) {
            setNoItemsFound(true);
          } else {
            setNoItemsFound(false);
          }

          // End the transaction after the search has been completed
          searchTransaction.end();
        });
    } else {
      setFilteredList([]);
      setNoItemsFound(false);

      // End the transaction if no search is performed
      searchTransaction.end();
    }
    // eslint-disable-next-line
  }, [debounce]);

  return (
    <Box position={"relative"} width={"100%"}>
      <Box
        display={"flex"}
        position={"relative"}
        overflow={"hidden"}
        width="100%"
        height={"40vh"}
        minHeight={"260px"}
      >
        <Box position={"absolute"} width={"100%"}>
          <Toolbar
            display="flex"
            sx={{
              flexWrap: "wrap",
              paddingY: "8px",
              justifyContent: "right",
              alignItems: "center",
              position: "relative",
            }}
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              disableRipple={true}
              sx={{ marginLeft: -1, marginRight: "auto" }}
              href="/"
              data-testid="logo"
            >
              <img
                src="/assets/logo.png"
                width="32px"
                alt="infra3D"
                className="logo-landing"
              />
              <Typography color="white">
                &ensp;infra3D Knowledge Base
              </Typography>
            </IconButton>
            <IconButton
              size="medium"
              aria-label="Help"
              aria-haspopup="true"
              onClick={handleHelp}
              color="primary"
              sx={{ ":hover": { color: "secondary.main" } }}
            >
              <HelpOutline fontSize="medium" />
            </IconButton>
            <IconButton
              size="medium"
              aria-label="Language"
              aria-haspopup="true"
              onClick={handleLanguageMenuOpen}
              color="primary"
              sx={{ ":hover": { color: "secondary.main" } }}
              data-testid="languagebutton"
            >
              <Language fontSize="medium" />
            </IconButton>
          </Toolbar>
        </Box>
        <img
          alt="bg"
          style={{ position: "absolute", zIndex: -1 }}
          src="/assets/landing.jpg"
        />
        <Box display={"flex"} width={"100%"} height="100%">
          <Box
            position={"relative"}
            display={"flex"}
            gap={"4px"}
            width={"30%"}
            minWidth="250px"
            padding={"4px 16px"}
            alignItems={"center"}
            sx={{
              marginY: "auto",
              marginX: "auto",
              marginBottom: "10vh",
              backgroundColor: "white",
              borderRadius: "40px",
            }}
          >
            <Search sx={{ color: "darkblue.light" }} />
            <InputBase
              placeholder={headerDatas.search_input_placeholder}
              sx={{
                backgroundColor: "white",
                width: "100%",
                color: "darkblue.main",
              }}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
              value={searchInput}
            />
          </Box>
        </Box>
        <LanguageRender
          anchorLang={anchorLang}
          language={language}
          isLanguageOpen={isLanguageOpen}
          handleLanguageClose={handleLanguageClose}
          changeLanguage={changeLanguage}
        />
      </Box>
      <Box
        width={"calc(30% + 32px)"}
        minWidth="282px"
        left={"50%"}
        top={"calc(30vh + 8px)"}
        position={"absolute"}
        display={"flex"}
        flexDirection={"column"}
        overflow={"hidden"}
        boxShadow={3}
        sx={{
          backgroundColor: "white",
          transform: "translate(-50%, 0)",
          borderRadius: "8px",
        }}
      >
        {noItemsFound && (
          <Box
            width={"100%"}
            underline="none"
            sx={{
              backgroundColor: "white",
            }}
            paddingY={1.5}
            color={"darkblue.main"}
          >
            <Typography textAlign={"center"}>
              {headerDatas.no_items_found}
            </Typography>
          </Box>
        )}
        {filteredList.map((item, index) => (
          <Link
            key={index}
            width={"100%"}
            underline="none"
            sx={{
              backgroundColor:
                index % 2 === 0 ? "white" : "darkblue.background",
              ":hover": {
                backgroundColor: "darkblue.backgroundHover",
                color: "secondary.main",
              },
            }}
            paddingX={3}
            paddingY={1.5}
            color={"darkblue.main"}
            href={"/docs/" + item.full_path}
          >
            <Typography>{item.title}</Typography>
          </Link>
        ))}
      </Box>
    </Box>
  );
}
