import { Box, Typography } from "@mui/material";
import React from "react";

export default function PrivacyPageFooter() {
  return (
    <Box
      width="100%"
      marginTop={"auto"}
      paddingY={1}
      sx={{ boxShadow: "0 0px 10px -2px gray" }}
    >
      <Typography
        paddingLeft={{ xs: 2, md: 6 }}
        color="primary.main"
        fontSize={"12px"}
      >
        ©2023 iNovitas AG. All Rights Reserved.
      </Typography>
    </Box>
  );
}
