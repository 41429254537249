import { Box, Typography } from "@mui/material";
import React from "react";

export default function LayoutFooter() {
  return (
    <Box
      width="100%"
      marginTop={"auto"}
      sx={{ backgroundColor: "darkblue.main" }}
      paddingLeft={{ xs: 2, md: 6 }}
      paddingY={1}
    >
      <Typography color="white" fontSize={"12px"}>
        ©2023 iNovitas AG. All Rights Reserved.
      </Typography>
    </Box>
  );
}
