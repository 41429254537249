import axios from "axios";

export function getApiURL(path = "") {
  return `https://cockpit.help.infra3d.com/api/content/${path}`;
}

export function fetchAPI(path, params) {
  const apiUrl = getApiURL(path);
  const datas = axios.create({
    baseURL: apiUrl,
    params: {
      ...params,
    },
    headers: {
      "api-key": "USR-972f4e71bd442ad4742db5243d7c4c931f255805",
    },
  });
  datas.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      return error;
    }
  );
  return datas;
}
