import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import DefaultPageContent from "../components/defaultPage/Content";
import DefaultPageHeader from "../components/defaultPage/Header";
import DefaultPageFooter from "../components/defaultPage/Footer";
import { fetchAPI } from "../api";
import Loading from "../components/loading/Loading";

export default function DefaultPage() {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("language"));
  const [headerDatas, setHeaderDatas] = useState();

  const changeLanguage = (lng) => {
    if (lng !== undefined) {
      setLanguage(lng);
      localStorage.setItem("language", lng);
    } else {
      setLanguage(null);
      localStorage.removeItem("language");
    }
  };

  useEffect(() => {
    fetchAPI("item/homepage", {
      locale: language === null ? "" : language,
      populate: 1,
    })
      .get()
      .then((response) => {
        setData(response.data);
      });
    fetchAPI("item/headerattributes", {
      locale: language === null ? "" : language,
      populate: 1,
    })
      .get()
      .then((response) => {
        setHeaderDatas(response.data);
      });
  }, [language]);

  useEffect(() => {
    if (data === undefined) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [data]);

  if (!data || isLoading || !headerDatas) {
    return <Loading />;
  }

  return (
    <Box
      width={"100%"}
      minHeight={"100vh"}
      display={"flex"}
      flexDirection={"column"}
    >
      <DefaultPageHeader
        headerDatas={headerDatas}
        changeLanguage={changeLanguage}
        language={language}
      />
      <DefaultPageContent data={data} />
      <DefaultPageFooter socialList={data.social_medias_list} />
    </Box>
  );
}
