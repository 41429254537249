import { Box } from "@mui/material";
import PrivacyPageHeader from "../components/privacy/Header";
import PrivacyPageContent from "../components/privacy/Content";
import PrivacyPageFooter from "../components/privacy/Footer";
import { useEffect, useState } from "react";
import { fetchAPI } from "../api";
import Loading from "../components/loading/Loading";

export default function Privacy() {
  const [language, setLanguage] = useState(localStorage.getItem("language"));
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  const changeLanguage = (lng) => {
    if (lng !== undefined) {
      setLanguage(lng);
      localStorage.setItem("language", lng);
    } else {
      setLanguage(null);
      localStorage.removeItem("language");
    }
  };

  useEffect(() => {
    fetchAPI("item/privacy", {
      locale: language === null ? "" : language,
      populate: 1,
    })
      .get()
      .then((response) => {
        console.log(response);
        setData(response.data);
      });
  }, [language]);

  useEffect(() => {
    if (data === undefined) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [data]);

  if (!data || isLoading) {
    return <Loading />;
  }

  return (
    <Box
      width="100%"
      minHeight="100vh"
      display="flex"
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"start"}
    >
      <PrivacyPageHeader changeLanguage={changeLanguage} language={language} />
      <PrivacyPageContent data={data} />
      <PrivacyPageFooter />
    </Box>
  );
}
