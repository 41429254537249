import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { init as initApm } from "@elastic/apm-rum";

const apm = initApm({
  serviceName: "infra3d-knowledge-base",
  serverUrl:
    "https://235afb67d66d4d529a6201b796a6c034.apm.eu-west-1.aws.cloud.es.io:443",
  flushInterval: 2000,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App apm={apm} />
    </BrowserRouter>
  </React.StrictMode>
);

export { apm };
