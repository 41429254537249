import React from "react";
import { Menu, MenuItem, Typography } from "@mui/material";

export default function LanguageRender(props) {
  return (
    <Menu
      anchorEl={props.anchorLang}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      data-testid="language"
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={props.isLanguageOpen}
      onClose={props.handleLanguageClose}
    >
      <MenuItem
        data-testid="deButton"
        sx={{
          gap: "10px",
          color: props.language === null ? "secondary.main" : "darkblue.main",
          ":hover": { color: "secondary.main" },
        }}
        onClick={() => {
          props.changeLanguage();
          props.handleLanguageClose();
        }}
      >
        <Typography variant="body1" fontWeight={500}>
          DE
        </Typography>
        <Typography variant="body2">Deutsch</Typography>
      </MenuItem>
      <MenuItem
        data-testid="enButton"
        sx={{
          gap: "10px",
          color: props.language?.includes("en")
            ? "secondary.main"
            : "darkblue.main",
          ":hover": { color: "secondary.main" },
        }}
        onClick={() => {
          props.changeLanguage("en");
          props.handleLanguageClose();
        }}
      >
        <Typography variant="body1" fontWeight={500}>
          EN
        </Typography>
        <Typography variant="body2">English</Typography>
      </MenuItem>
      <MenuItem
        data-testid="frButton"
        sx={{
          gap: "10px",
          color: props.language?.includes("fr")
            ? "secondary.main"
            : "darkblue.main",
          ":hover": { color: "secondary.main" },
        }}
        onClick={() => {
          props.changeLanguage("fr");
          props.handleLanguageClose();
        }}
      >
        <Typography variant="body1" fontWeight={500}>
          FR
        </Typography>
        <Typography variant="body2">Français</Typography>
      </MenuItem>
    </Menu>
  );
}
