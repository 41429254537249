import { Box, Typography } from "@mui/material";
import React from "react";

export default function LayerContent({ article }) {
  return (
    <Box position={"relative"} overflow={"hidden"} width="100%" height="100%">
      <Box
        height={{ xs: "calc(100% - 32px)", md: "calc(100% - 48px)" }}
        overflow={"hidden"}
        paddingY={{ xs: 4, md: 6 }}
        paddingX={{ xs: 2, md: 4 }}
      >
        <Typography marginBottom={{ xs: 2, md: 6 }}>
          infra3D Knowledge Base
        </Typography>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={2}
          height={{ xs: "calc(100% - 72px)", md: "calc(100% - 112px)" }}
          overflow={"auto"}
        >
          <Typography
            fontSize={{ xs: "26px", md: "32px" }}
            color={"darkblue.main"}
            fontWeight={600}
            lineHeight={"normal"}
          >
            {article.title}
          </Typography>
          <Typography color={"darkblue.main"}>
            <div dangerouslySetInnerHTML={{ __html: article.description }} />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
