import {
  Box,
  Divider,
  Container,
  Typography,
  Tooltip,
  Link,
} from "@mui/material";
import React from "react";

export default function DefaultPageFooter({ socialList }) {
  return (
    <Box width="100%" marginTop={"auto"}>
      <Container
        sx={{
          marginTop: 8,
          paddingY: "32px",
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
          justifyContent: "space-between",
          gap: "16px",
        }}
      >
        <Box
          display={"flex"}
          color="darkblue.main"
          flexDirection={"column"}
          gap={1}
          alignItems={{ xs: "center", md: "start" }}
        >
          <Typography fontWeight={"600"}>iNovitas AG</Typography>
          <Typography>
            Schlossbergweg 3<br />
            CH-5400 Baden
          </Typography>
          <Typography>+41 56 552 05 70</Typography>
          <Typography>info@inovitas.ch</Typography>
        </Box>
        <Box
          width={{ xs: "100%", md: "50%" }}
          minHeight={"100px"}
          sx={{
            padding: "0",
            color: "white",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2696.9057173892093!2d8.30299447676559!3d47.47226669724383!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47906d7ffb8ee537%3A0x3961258afe702412!2sSchlossbergweg%203%2C%205400%20Baden%2C%20Switzerland!5e0!3m2!1sen!2s!4v1689253021883!5m2!1sen!2s"
            style={{ width: "100%", height: "100%", border: "none" }}
            allowFullScreen={true}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Our location"
          ></iframe>
        </Box>
      </Container>
      <Divider sx={{ backgroundColor: "darkblue.main" }} />
      <Container
        sx={{
          paddingY: "32px",
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
          justifyContent: "space-between",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <Box
          width={{ xs: "100%", md: "50%" }}
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", lg: "row" },
            justifyContent: "space-between",
            alignItems: { xs: "center", md: "start" },
            gap: "8px",
            color: "darkblue.main",
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            ©2023 iNovitas AG. All Rights Reserved.
          </Typography>
          <Link
            target="_blank"
            href="https://www.infra3d.com/agb/"
            underline="none"
            variant="body2"
            fontWeight={600}
            color={"darkblue.main"}
          >
            AGB
          </Link>
          <Link
            target="_blank"
            href="/privacy"
            underline="none"
            variant="body2"
            fontWeight={600}
            color={"darkblue.main"}
          >
            Imprint & Privacy
          </Link>
        </Box>
        <Box
          width={{ xs: "100%", md: "50%" }}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: { xs: "center", md: "right" },
            gap: "8px",
            color: "darkblue.main",
          }}
        >
          {socialList.map((item, index) => (
            <Tooltip
              key={index}
              title={item.name}
              placement="top"
              enterDelay={400}
              classes={{ tooltip: "styleTooltip" }}
            >
              <Link
                display={"flex"}
                width="24px"
                height="24px"
                padding={"4px"}
                overflow={"hidden"}
                href={item.url_link}
                target="_blank"
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  borderRadius: "4px",
                  backgroundColor: item.background_color,
                }}
              >
                {item?.logo?.path !== undefined && (
                  <img
                    alt={item.alt}
                    width="100%"
                    height="auto"
                    src={
                      "https://cockpit.help.infra3d.com/storage/uploads" +
                      item.logo.path
                    }
                  />
                )}
              </Link>
            </Tooltip>
          ))}
        </Box>
      </Container>
    </Box>
  );
}
