import { Link, Skeleton, Typography } from "@mui/material";
import React from "react";

export default function NewestPosts({ item }) {
  return (
    <Link
      display={"flex"}
      flexDirection={"column"}
      gap={1}
      paddingX={2}
      paddingY={2}
      sx={{
        boxShadow: 2,
        borderRadius: "8px",
        ":hover": {
          backgroundColor: "#0000000A",
        },
      }}
      href={"/docs/" + item.link_to_page.full_path}
      underline="none"
    >
      {item?.image?.path !== undefined ? (
        <img
          alt={item.alt}
          loading="lazy"
          width="100%"
          height="auto"
          src={
            "https://cockpit.help.infra3d.com/storage/uploads" + item.image.path
          }
        />
      ) : (
        <Skeleton variant="rectangular" width={"100%"} height={220} />
      )}
      <Typography color={"darkblue.main"} fontSize={20} fontWeight={600}>
        {item.link_to_page.title}
      </Typography>
      <Typography color={"darkblue.light"}>{item.description}</Typography>
    </Link>
  );
}
