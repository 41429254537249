import { ArrowForwardIos, FiberManualRecord } from "@mui/icons-material";
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

export default function LayoutSidebar({ data }) {
  const navigate = useNavigate();
  const params = useParams();
  const [open, setOpen] = useState([]);
  const [pathList, setPathList] = useState([]);
  const path = Object.keys(params);

  const handleSelectedRow = (id) => {
    navigate("/docs/" + id);
    if (open[0] === id) {
      setOpen([]);
    } else {
      setOpen([id]);
    }
  };

  const handleSelectedRowInside = (id) => {
    navigate("/docs/" + id);
    if (open.includes(id)) {
      setOpen(open.filter((item) => item !== id));
    } else {
      setOpen([...open, id]);
    }
  };

  const handleSelectedChildren = (id) => {
    navigate("/docs/" + id);
  };

  useEffect(() => {
    const paths = [];
    path.forEach((element) => {
      paths.push(params[element]);
    });
    setPathList(paths);
    setOpen(paths);
    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    if (params.id === undefined) {
      navigate("/docs/" + data[0].page.route);
    }
    // eslint-disable-next-line
  }, []);

  const renderChildren = (route, item) => {
    return (
      <ListItemButton
        key={item.title}
        onClick={() =>
          handleSelectedChildren(
            (route !== "" ? route + "/" : "") + item.page.route
          )
        }
      >
        <ListItemIcon sx={{ minWidth: "auto", paddingRight: 1 }}>
          <FiberManualRecord
            sx={{
              fontSize: "8px",
              color: pathList.includes(item.page.route)
                ? "secondary.main"
                : "darkblue.main",
            }}
          />
        </ListItemIcon>
        <ListItemText
          sx={{
            color: pathList.includes(item.page.route)
              ? "secondary.main"
              : "darkblue.main",
          }}
        >
          {item.title}
        </ListItemText>
      </ListItemButton>
    );
  };

  const renderFolder = (route, item) => {
    return (
      <React.Fragment key={item.title}>
        <ListItemButton
          onClick={() => handleSelectedRowInside(route + "/" + item.page.route)}
        >
          <ListItemIcon sx={{ minWidth: "auto", paddingRight: 1 }}>
            <ArrowForwardIos
              sx={{
                rotate: open.includes(item.page.route) ? "90deg" : "0deg",
                transition: "rotate 0.2s",
                color: pathList.includes(item.page.route)
                  ? "secondary.main"
                  : "darkblue.main",
              }}
            />
          </ListItemIcon>
          <ListItemText
            sx={{
              color: pathList.includes(item.page.route)
                ? "secondary.main"
                : "darkblue.main",
            }}
          >
            {item.title}
          </ListItemText>
        </ListItemButton>
        <Collapse
          in={open.includes(item.page.route)}
          timeout="auto"
          unmountOnExit
          sx={{ paddingLeft: 2 }}
        >
          <List component="div" disablePadding>
            {item._children.map((items) =>
              items._children.length === 0
                ? renderChildren(route + "/" + item.page.route, items)
                : renderFolder(route + "/" + item.page.route, items)
            )}
          </List>
        </Collapse>
      </React.Fragment>
    );
  };

  return (
    <Box width="100%" display={"flex"} flexDirection={"column"}>
      <List
        sx={{ width: "calc(100% - 8px)", height: "100%", overflowX: "hidden" }}
        component={"nav"}
      >
        {data.map((item) =>
          item._children.length === 0 ? (
            renderChildren("", item)
          ) : (
            <React.Fragment key={item.title}>
              <ListItemButton
                onClick={() => handleSelectedRow(item.page.route)}
              >
                <ListItemIcon sx={{ minWidth: "auto", paddingRight: 1 }}>
                  <ArrowForwardIos
                    sx={{
                      rotate: open.includes(item.page.route) ? "90deg" : "0deg",
                      transition: "rotate 0.2s",
                      color: pathList.includes(item.page.route)
                        ? "secondary.main"
                        : "darkblue.main",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    color: pathList.includes(item.page.route)
                      ? "secondary.main"
                      : "darkblue.main",
                  }}
                >
                  {item.title}
                </ListItemText>
              </ListItemButton>
              <Collapse
                in={open.includes(item.page.route)}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding sx={{ paddingLeft: 2 }}>
                  {item._children.map((items) =>
                    items._children.length === 0
                      ? renderChildren(item.page.route, items)
                      : renderFolder(item.page.route, items)
                  )}
                </List>
              </Collapse>
            </React.Fragment>
          )
        )}
      </List>
    </Box>
  );
}
