import React, { useEffect, useState } from "react";
import { HelpOutline, Language, Search } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputBase,
  Link,
  Toolbar,
  Typography,
} from "@mui/material";
import LanguageRender from "../renders/languageRender";
import { fetchAPI } from "../../api";
import { useDebounce } from "use-debounce";

export default function LayoutHeader({
  headerDatas,
  changeLanguage,
  language,
}) {
  const [anchorLang, setAnchorLang] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [noItemsFound, setNoItemsFound] = useState(false);
  const [debounce] = useDebounce(searchInput, 200);
  const isLanguageOpen = Boolean(anchorLang);

  const handleHelp = () => {
    window.location.href =
      "https://inovitas.atlassian.net/servicedesk/customer/portals";
  };

  const handleLanguageMenuOpen = (event) => {
    setAnchorLang(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setAnchorLang(null);
  };

  useEffect(() => {
    if (debounce !== "") {
      fetchAPI("items/pages", {
        filter: JSON.stringify({
          $or: [
            {
              title: {
                $regex: debounce,
                $options: "i",
              },
            },
            {
              description: {
                $regex: debounce,
                $options: "i",
              },
            },
          ],
        }),
        locale: language === null ? "" : language,
        populate: 1,
      })
        .get()
        .then((response) => {
          setFilteredList(response.data);
          if (response.data.length === 0) {
            setNoItemsFound(true);
          } else {
            setNoItemsFound(false);
          }
        });
    } else {
      setFilteredList([]);
      setNoItemsFound(false);
    }
    // eslint-disable-next-line
  }, [debounce]);

  return (
    <Box display={"flex"} position={"relative"} width="100%">
      <Box width={"100%"} sx={{ backgroundColor: "darkblue.main" }}>
        <Toolbar
          display="flex"
          sx={{
            flexWrap: "wrap",
            paddingY: "8px",
            justifyContent: "right",
            alignItems: "center",
            position: "relative",
          }}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            disableRipple={true}
            sx={{ marginLeft: -1, marginRight: "auto" }}
            href="/"
            data-testid="logo"
          >
            <img
              src="/assets/logo.png"
              width="32px"
              alt="infra3D"
              className="logo-landing"
            />
            <Typography color="white">&ensp;infra3D Knowledge Base</Typography>
          </IconButton>
          <IconButton
            size="medium"
            aria-label="Help"
            aria-haspopup="true"
            onClick={handleHelp}
            color="primary"
            sx={{ ":hover": { color: "secondary.main" } }}
          >
            <HelpOutline fontSize="medium" />
          </IconButton>
          <IconButton
            size="medium"
            aria-label="Language"
            aria-haspopup="true"
            onClick={handleLanguageMenuOpen}
            color="primary"
            sx={{ ":hover": { color: "secondary.main" } }}
            data-testid="languagebutton"
          >
            <Language fontSize="medium" />
          </IconButton>
          <Box
            position={"relative"}
            width={{ xs: "100%", md: "auto" }}
            marginLeft={2}
            display={"flex"}
            gap={"4px"}
            minWidth="150px"
            padding={"4px 16px"}
            alignItems={"center"}
            sx={{
              backgroundColor: "white",
              borderRadius: "40px",
            }}
          >
            <Search sx={{ color: "darkblue.light" }} />
            <InputBase
              placeholder={headerDatas.search_input_placeholder}
              sx={{
                backgroundColor: "white",
                width: "100%",
                color: "darkblue.main",
              }}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
              value={searchInput}
            />
            <Box
              zIndex={1}
              width={"100%"}
              minWidth="182px"
              left={{ xs: "50%", md: "140px" }}
              top={"calc(50% + 24px)"}
              position={"absolute"}
              display={"flex"}
              flexDirection={"column"}
              overflow={"hidden"}
              boxShadow={3}
              sx={{
                backgroundColor: "white",
                transform: "translate(-50%, 0)",
                borderRadius: "8px",
              }}
            >
              {noItemsFound && (
                <Box
                  width={"100%"}
                  underline="none"
                  sx={{
                    backgroundColor: "white",
                  }}
                  paddingY={1.5}
                  color={"darkblue.main"}
                >
                  <Typography textAlign={"center"}>
                    {headerDatas.no_items_found}
                  </Typography>
                </Box>
              )}
              {filteredList.map((item, index) => (
                <Link
                  key={index}
                  width={"100%"}
                  underline="none"
                  sx={{
                    backgroundColor:
                      index % 2 === 0 ? "white" : "darkblue.background",
                    ":hover": {
                      backgroundColor: "darkblue.backgroundHover",
                      color: "secondary.main",
                    },
                  }}
                  paddingX={3}
                  paddingY={1.5}
                  color={"darkblue.main"}
                  href={"/docs/" + item.full_path}
                >
                  <Typography>{item.title}</Typography>
                </Link>
              ))}
            </Box>
          </Box>
        </Toolbar>
      </Box>

      <LanguageRender
        anchorLang={anchorLang}
        language={language}
        isLanguageOpen={isLanguageOpen}
        handleLanguageClose={handleLanguageClose}
        changeLanguage={changeLanguage}
      />
    </Box>
  );
}
