import { Box, Container, Typography } from "@mui/material";
import React from "react";

export default function PrivacyPageContent({ data }) {
  return (
    <Box width={"100%"} paddingY={"48px"}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Typography
          fontSize={{ xs: "26px", md: "32px" }}
          color={"darkblue.main"}
          fontWeight={600}
        >
          {data.title}
        </Typography>
        <Typography color={"darkblue.main"}>
          <div dangerouslySetInnerHTML={{ __html: data.description }} />
        </Typography>
      </Container>
    </Box>
  );
}
