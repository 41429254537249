import React from "react";
import DefaultPage from "./pages/DefaultPage";
import KnowledgeBase from "./pages/KnowledgeBase";
import Privacy from "./pages/Privacy";
import Page404 from "./pages/Page404";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import { Route, Routes } from "react-router-dom";
import "./App.css";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<DefaultPage />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/docs/:id" element={<KnowledgeBase />} />
        <Route path="/docs/:id/:id2" element={<KnowledgeBase />} />
        <Route path="/docs/:id/:id2/:id3" element={<KnowledgeBase />} />
        <Route path="/docs/:id/:id2/:id3/:id4" element={<KnowledgeBase />} />
        <Route
          path="/docs/:id/:id2/:id3/:id4/:id5"
          element={<KnowledgeBase />}
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
