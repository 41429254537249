import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      light: "#c4dce9",
      main: "#0d92D2",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: will be calculated from palette.secondary.main,
      main: "#ff6700",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#fff",
    },
    darkblue: {
      main: "#053952",
      light: "rgba(5, 57, 82, 0.60)",
      background: "rgba(5, 57, 82, 0.1)",
      backgroundHover: "rgba(5, 57, 82, 0.2)",
    },
    grey: {
      main: "#777777",
    },
  },
  typography: {
    fontFamily: "Roboto",
    h1: {
      fontFamily: "Roboto",
      fontWeight: 500,
      fontSize: "72px",
      lineHeight: "96px",
      letterSpacing: 0,
    },
    h2: {
      fontFamily: "Roboto",
      fontWeight: 500,
      fontSize: "51px",
      lineHeight: "72px",
      letterSpacing: 0,
    },
    h3: {
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "36px",
      lineHeight: "48px",
      letterSpacing: 0,
    },
    h4: {
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: "26px",
      letterSpacing: 0,
    },
    h5: {
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "24px",
      letterSpacing: 0,
    },
    h6: {
      fontFamily: "Roboto",
      fontWeight: 300,
      fontSize: "18px",
      lineHeight: "24px",
      letterSpacing: 0,
    },
    subtitle1: {
      fontFamily: "Roboto",
    },
    subtitle2: {
      fontFamily: "Roboto",
    },
    body1: {
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "24px",
      letterSpacing: 0,
    },
    body2: {
      fontFamily: "Roboto",
      fontWeight: 300,
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: 0,
    },
    button: {
      fontFamily: "Roboto",
    },
    caption: {
      fontFamily: "Roboto",
      fontWeight: 300,
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: 0,
    },
    overline: {
      fontFamily: "Roboto",
    },
  },
});
