import React, { useState, useEffect } from "react";
import LayoutHeader from "../components/layout/Header";
import LayoutFooter from "../components/layout/Footer";
import LayoutSidebar from "../components/layout/Sidebar";
import { Box, IconButton } from "@mui/material";
import { ArrowBackIosNewOutlined, MenuOutlined } from "@mui/icons-material";
import LayerContent from "../components/layout/Content";
import { fetchAPI } from "../api";
import Loading from "../components/loading/Loading";
import { useParams } from "react-router-dom";

export default function KnowledgeBase() {
  const params = useParams();
  const [datas, setDatas] = useState();
  const [showSidebar, setShowSidebar] = useState(true);
  const [sidebar, setSidebar] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("language"));
  const [headerDatas, setHeaderDatas] = useState();
  const [selectedItem, setSelectedItem] = useState("");
  const [article, setArticle] = useState({
    title: "Loading...",
    description: "",
  });

  const changeLanguage = (lng) => {
    if (lng !== undefined) {
      setLanguage(lng);
      localStorage.setItem("language", lng);
    } else {
      setLanguage(null);
      localStorage.removeItem("language");
    }
  };

  useEffect(() => {
    if (sidebar) {
      const route = Object.keys(params);
      let sidebarFolder = sidebar;
      for (let i = 0; i < route.length; i++) {
        for (let j = 0; j < sidebarFolder.length; j++) {
          if (sidebarFolder[j].page.route === params[route[i]]) {
            if (route.length - 1 === i) {
              setSelectedItem(sidebarFolder[j].page._id);
            } else {
              sidebarFolder = sidebarFolder[j]._children;
            }
            break;
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [sidebar, params]);

  useEffect(() => {
    fetchAPI("tree/sidebarlist", {
      locale: language === null ? "" : language,
      populate: 10,
    })
      .get()
      .then((response) => {
        setDatas(response.data);
      });
    fetchAPI("item/headerattributes", {
      locale: language === null ? "" : language,
      populate: 1,
    })
      .get()
      .then((response) => {
        setHeaderDatas(response.data);
      });
  }, [language]);

  useEffect(() => {
    if (selectedItem !== "") {
      fetchAPI("item/pages/" + selectedItem, {
        locale: language === null ? "" : language,
        populate: 1,
      })
        .get()
        .then((response) => {
          setArticle(response.data);
        });
    }
    // eslint-disable-next-line
  }, [language, selectedItem]);

  useEffect(() => {
    if (datas === undefined) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      setSidebar(datas);
    }
  }, [datas]);

  if (!sidebar || isLoading || !headerDatas) {
    return <Loading />;
  }

  return (
    <Box
      width={"100%"}
      height={"100vh"}
      display={"flex"}
      flexDirection={"column"}
      overflow={"hidden"}
    >
      <LayoutHeader
        sidebar={sidebar}
        headerDatas={headerDatas}
        changeLanguage={changeLanguage}
        language={language}
      />
      <Box
        position={"relative"}
        display={"flex"}
        width="100%"
        height={{ xs: "calc(100vh - 160px)", md: "calc(100vh - 120px)" }}
      >
        <Box
          position={"relative"}
          width={{ xs: showSidebar ? "100%" : "0%", md: "20%" }}
          marginLeft={{ sx: "0%", md: showSidebar ? "0%" : "-20%" }}
          overflow={"auto"}
          sx={{
            transition: "margin 0.3s , width 0.3s",
          }}
        >
          <LayoutSidebar data={sidebar} />
        </Box>
        <Box
          width="0px"
          sx={{
            border: "1px #053952 solid",
            backgroundColor: "darkblue.main",
            transition: "margin 0.3s",
          }}
          height={"100%"}
          marginRight={{ xs: showSidebar ? "40px" : "20px", md: "20px" }}
          marginLeft={showSidebar ? "20px" : "40px"}
          position={"relative"}
        >
          <IconButton
            onClick={() => setShowSidebar(!showSidebar)}
            sx={{
              position: "absolute",
              left: "-20px",
              top: { xs: "20px", md: "40px" },
              backgroundColor: "white",
              border: "1px #053952 solid",
              color: "darkblue.main",
              ":hover": { backgroundColor: "white", color: "secondary.main" },
            }}
          >
            {showSidebar ? <ArrowBackIosNewOutlined /> : <MenuOutlined />}
          </IconButton>
        </Box>
        <Box
          width={{ xs: showSidebar ? "0%" : "100%", md: "calc(80% - 40px)" }}
          sx={{ transition: "width 0.3s" }}
          overflowY="auto"
        >
          {article !== undefined && <LayerContent article={article} />}
        </Box>
      </Box>
      <LayoutFooter />
    </Box>
  );
}
