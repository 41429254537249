import { AutoStoriesOutlined } from "@mui/icons-material";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import NewestPosts from "../cards/NewestPosts";

export default function DefaultPageContent({ data }) {
  return (
    <Box paddingBottom={"32px"} paddingTop={"48px"}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Typography marginBottom={"40px"} color={"darkblue.main"}>
          {data.welcome_message.map((text, index) =>
            index % 2 === 0 ? (
              <span key={index} style={{ fontWeight: "600" }}>
                {text}
              </span>
            ) : (
              text
            )
          )}
        </Typography>
        <Divider sx={{ backgroundColor: "darkblue.main" }} />
        <Box display={"flex"} flexDirection={"column"} gap={2}>
          <Box display={"flex"} color="darkblue.main" gap={1}>
            <AutoStoriesOutlined />
            <Typography fontWeight={600}>{data.cards_title}</Typography>
          </Box>
          <Grid container>
            {data.cards_list.map((item, index) => (
              <Grid
                key={index}
                item
                xs={12}
                md={6}
                xl={4}
                marginBottom={1}
                padding={{
                  xs: "0px",
                  md: index % 2 === 0 ? "0px 8px 0px 0px" : "0px 0px 0px 8px",
                  xl:
                    index % 3 === 0
                      ? "0px 12px 0px 0px"
                      : index % 3 === 1
                      ? "0px 6px 0px 6px"
                      : "0px 0px 0px 12px",
                }}
              >
                <NewestPosts item={item} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
