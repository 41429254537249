import { Box, Button, CardMedia, Typography } from "@mui/material";

export default function Page404() {
  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        gap={2}
      >
        <CardMedia
          style={{
            width: "auto",
            maxHeight: "350px",
            display: "flex",
            justifyContent: "center",
          }}
          component="img"
          src="/assets/404.jpg"
          alt="Designed by pch.vector / Freepik"
        />
        <Typography color={"darkblue.main"}>Page not found!</Typography>
        <Button variant="contained" href="/">
          Back to homepage
        </Button>
      </Box>
    </Box>
  );
}
